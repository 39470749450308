<template>
  <div class="capitalFlow bg-white">
    <p class="common-tip-title mb-20 mt-20 ml-10">利润明细</p>

<!--    <ul class="tab-box">-->
<!--      <li v-for="(item, index) in tabsVal" :key="index" :class="indexs === index ? 'tab-active' : 'tab-item'" @click="clickChanges(item, index)">{{ item.title }}</li>-->
<!--    </ul>-->
    <div style="padding: 10px 30px;">
      <el-row :gutter="20">
        <el-col :span="5.5">
          <div class="grid-content bg-purple">
            <el-date-picker
              v-model="value2"
              :clearable="false"
              :picker-options="pickerOptions"
              align="left"
              end-placeholder="结束日期"
              range-separator="至"
              size="small"
              start-placeholder="开始日期"
              type="daterange"
              unlink-panels
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
        </el-col>

<!--        <el-col :span="4">-->
<!--          <div class="grid-content bg-purple">-->
<!--            <el-input v-model="payPrice" placeholder="请输入销售价" size="small"></el-input>-->
<!--          </div>-->
<!--        </el-col>-->

        <el-col :span="3">
          <div class="grid-content bg-purple">
            <el-input v-model="inputValue" placeholder="请输入订单号" size="small"></el-input>
          </div>
        </el-col>
        <el-col :span="2.5">
            <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
        </el-col>
        <el-col :span="2.5">
            <el-button size="small" @click="onReset">重置</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-content">
      <div class="price-box">
        <span v-show="type == 0" class="price-des">成本：<span class="price-txt">￥{{amount.costPrice?amount.costPrice.toFixed(2):'0.00'}}</span></span>
        <span class="price-des">利润：<span class="price-txt">￥{{amount.profit?amount.profit.toFixed(2):'0.00'}}</span></span>
      </div>
      <div class="container">
        <div class="left"></div>
        <div class="right">
          <div class="right-wrapper">
            <el-table :data="tableData" border  style="width: 100%">
              <el-table-column :index="indexMethod" align="center" label="序号" type="index"  width="80"></el-table-column>
              <el-table-column align="center" label="订单号" prop="orderNo" width="180"></el-table-column>
              <el-table-column align="center" label="成本" prop="costPrice"></el-table-column>
              <el-table-column align="center" label="销售价" prop="payPrice"></el-table-column>
              <el-table-column  align="center" label="利润" prop="profit"></el-table-column>
              <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
            </el-table>
            <div style="justify-content: right;display: flex; margin-top: 20px;">
              <el-pagination
                :current-page="page.currentPage"
                :page-size="page.size"
                :page-sizes="[10, 20, 30]"
                :total="page.total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pickerOptions } from '@/views/User/FinancialManagement/dateCollation'

import { turnoverAmountPage } from '@/api/capital'

export default {
  name: 'FundingBillsIndex',
  data() {
    return {
      // 表格数据
      tableData: [],
      // 流水类型
      options1: [],
      optionsArray1: [],
      // 流水类型值
      waterValue: '',
      // 支付方式值
      // 业务单号
      inputValue: '',
      //销售价
      payPrice:null,
      // 标签页类型
      type: '',
      // 时间选择值
      value2: '',
      // 标签内容
      indexs: 0,
      tabsVal: [{
        type: 0,
        title: '商品订单'
      }],
      page:{
        currentPage:1,
        size:10,
        total:0
      },
      amount:{
        profit:null,
        costPrice:null
      },
      date:{
        startTime:null,
        endTime:null
      },
      payStatus:null,
      pickerOptions
    }
  },
  created() {
    this.getPage(this.page)
  },
  methods: {
    getPage(page){
      turnoverAmountPage({
        current: page.currentPage,
        size: page.size,
        startTime:this.value2 === null ? null : this.value2[0],
        endTime:this.value2 === null ? null : this.value2[1],
        orderNo: this.inputValue || null
      }).then(res => {
        this.tableData = res.data.page.records
        this.amount = res.data.amount
        this.page.total = res.data.page.total
        this.page.size = res.data.page.size
        this.page.currentPage = res.data.page.current
      }).catch((err) => {

      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    indexMethod(index) {
      return index +1
    },
    // 查询
    onSubmit() {
      this.getPage(this.page)
      console.log('value2',this.value2)
    },
    clickChanges(e, index) {
      this.indexs = index
      this.type = e.type
    },
    // 重置
    onReset() {
      this.waterValue = ''
      this.value2 = ''
      this.inputValue = ''
      this.getPage(this.page)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
}
.left {
  width: 0px;
}
.right{
  flex: 1;
  position: relative;
}
.right-wrapper{
  width: 100%;
}
.price-box {
  margin-bottom: 25px;
  text-align: right;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: #666;
  .price-des {
    padding: 0 19px;
    border-right: 1px solid #dcdfe6;
  }
  .price-des:last-child {
    border: none;
    padding-right: 0;
  }
  .price-txt {
    font-weight: 700;
    color: #df1234;
  }
}
.table-content {
  margin-top: 16px;
  padding: 30px;
  padding-top: 0px;
}
.tab-active, .tab-item {
  width: 86px;
  height: 40px;
  font-size: 14px;
  background: #f9f9f9;
  border-radius: 4px 4px 0 0;
  color: #666;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
.tab-active {
  font-weight: 700;
  color: #2ba7ff;
  background: #fff;
  border: none;
}
.capitalFlow {
  .tab-box {
    display: flex;
  }
}
ul {
  padding: 0;
}
</style>
